import React from 'react';
import '../../index.css'; // Make sure Tailwind CSS is included in your index.css
import PhoneImage from '../../assets/images/iPhone 15.png';
import credential from '../../assets/images/credential.png';
import grow from '../../assets/images/grow.png';
import connections from '../../assets/images/connections.png';
import backgroundImg from '../../assets/images/image21.png';

const EmpowerCareerJourney = () => {
    const backgroundImageStyle = {
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    };

    return (
        <div className="flex items-center justify-center h-screen" style={backgroundImageStyle}>
            <div className="w-fit mx-auto flex justify-center items-stretch space-x-2">
                <div className="flex-none">
                    <img
                        decoding="async"
                        width="537"
                        src={PhoneImage}
                        alt=""
                        sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 768px, 100vw"
                    />
                </div>
                <div className="flex flex-col flex-grow space-y-4">
                    <div className="text-center">
                        <p className="primary-heading mb-2">Empower Your Career Journey</p>
                        <p className="primary-text mb-4">
                            Your skills, your opportunities, your future
                        </p>
                        <p className="text-l mb-4">
                            Unlock opportunities with Lillup’s Talent Passport. Our blockchain and
                            AI tech put your skills on the global stage.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
                        <div className="border border-red-600 shadow-lg p-2 rounded-lg w-64 grid grid-rows-2 bg-white">
                            <div className="float-left">
                                <img src={credential} alt="" className="mb-2 w-32 h-32" />
                            </div>
                            <div className="text-left">
                                <span className="block primary-text mb-2">
                                    Authentic Credentials
                                </span>
                                <span className="block">
                                    Elevate your skillset with verifiable credentials.
                                </span>
                            </div>
                        </div>
                        <div className="border border-red-600 shadow-lg p-2 rounded-lg w-64 grid grid-rows-2 bg-white">
                            <div className="float-left">
                                <img src={grow} alt="" className="mb-2 w-32 h-32" />
                            </div>
                            <div className="text-left">
                                <span className="block primary-text mb-2">Continuous Growth</span>
                                <span className="block">
                                    Tailor your learning journey to your career goals.
                                </span>
                            </div>
                        </div>
                        <div className="border border-red-600 shadow-lg p-2 rounded-lg w-64 grid grid-rows-2 bg-white">
                            <div className="float-left">
                                <img src={connections} alt="" className="mb-2 w-32 h-32" />
                            </div>
                            <div className="text-left">
                                <span className="block primary-text mb-2">
                                    Worldwide Connections
                                </span>
                                <span className="block">
                                    Link up with leading employers around the globe.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 pt-6 text-center justify-center flex">
                        <a
                            href="https://www.acellus.com/for-your-school/video/Acellus_Writing_Tutor.mp4"
                            className="primary-button w-48">
                            Get Started
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmpowerCareerJourney;
