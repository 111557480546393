import './App.css';
import Contact from './Pages/Home/Contact';
import Home from './Pages/Home/Home';
import React, { Fragment } from 'react';
import EmpowerCareerJourney from './Pages/Home/empower-career-journey';
import RefineTalent from './Pages/Home/refine-talent';
import Footer from './Pages/Home/footer';

function App() {
    return (
        <>
            <Fragment>
                <Home />
                <Contact />
                <EmpowerCareerJourney />
                <RefineTalent />
                <Footer />
            </Fragment>
        </>
    );
}

export default App;
